footer#footer {
    height: 56px;
    background-color: $primary-color;
    color: $white;
    padding: 15px 0.9375rem 5px;

    ul {
        list-style-type: none;

        li {
            display: inline-block;
            margin-left: 10px;
        }
    }

    a {
        color: $white;
    }
}