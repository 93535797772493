@import "util/util";

$global-flexbox: true !default;
$grid-background-total-columns: 12;

$primary-color: #56c310;
$secondary-color: #000000;

$foundation-palette: (
    primary:   #56c310,
    secondary: #000000,
    success:   #56c310,
    warning:   #56c310,
    alert:     #56c310,
);

$margin-default: 20px;