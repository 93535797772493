.fullscreen-image {
    clear: both;
}

body {
    background-image: url(/images/logo-big.jpg);
    background-repeat: no-repeat;
    background-position: bottom 60px right 10px;
    background-attachment: fixed;
    background-size: 200px;

    @include breakpoint(medium down) {
        background: none;
    }
}