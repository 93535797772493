.teasergroup {
    margin-top: $margin-default * 1.5;

    .teaser {
        margin-bottom: $margin-default * 1.5;
        position: relative;

        @include breakpoint(large up) {
            &:hover {
                .caption {
                    color: $primary-color;
                }

                .teaser-text {
                    height: 100%;
                    padding: 10px;
                }
            }
        }
    }

    .teaser-full {
        img {
            filter: blur(2px);
            transition: all ease-in-out .3s;
        }

        &:hover {
            img {
                filter: blur(0px);
            }
        }
    }

    img {
        width: 100%;
    }

    .caption {
        position: absolute;
        bottom: 0;
        left: 0.9375rem;
        display: inline-block;
        width: calc(100% - (0.9375rem * 2));
        padding: 10px;
        background: $secondary-color;
        background: rgba($secondary-color, .6);
        color: $white;
        transition: color ease-in-out .3s;
        z-index: 3;
    }

    .teaser-text {
        position: absolute;
        bottom: 0;
        left: 0.9375rem;
        width: calc(100% - (0.9375rem * 2));
        padding: 0 10px;
        z-index: 2;
        height: 0%;
        overflow: hidden;
        transition: all ease-in-out .6s;
        background: rgba($secondary-color, .6);
        color: $white;
    }

    @include breakpoint(medium down) {
        .teaser {
            margin-bottom: 80px;

            &:last-of-type {
                margin-bottom: 30px;
            }
        }

        > :not(.teaser-full) {
            clear: both;

            img {
                max-width: 50%;
                float: right;
            }

            .caption {
                width: calc(50% - (0.9375rem));
                right: 0.9375rem;
                left: auto;
            }

            .teaser-text {
                width: calc(50% );
                position: relative;
                height: 100%;
                color: $body-font-color;
                background-color: transparent;
                left: auto;
                display: inline-block;
                text-align: left;
            }

            &:nth-child(even) {
                img {
                    float: left;
                }

                .caption {
                    left: 0.9375rem;
                    right: auto;
                }

                .teaser-text {
                    text-align: left;
                }
            }
        }
    }
}