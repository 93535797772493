nav.nav {
    ul {
        list-style-type: none;
        margin: 0;
    }

    li {
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 10px;
        line-height: 50px;
        color: $white;
        transition: line-height ease-in-out .2s;
    }

    &.fixed {
        li {
            line-height: 35px;
        }
    }
}

.close-mobile-nav {
    cursor: pointer;
}

@include breakpoint(medium down) {
    #nav-mobile-menu {
        position: fixed;
        right: -200px;
        height: 120%;
        transition: right ease-in-out .3s;
        width: 200px;
        background-color: $light-gray;
        box-shadow: inset 1px -6px 5px 0 rgba(black, .5);

        li {
            display: block;
            margin-left: 0;
            color: $body-font-color;

            &.label {
                color: $white;
                line-height: 39px;
            }
        }

        &.active {
            right: 0;
        }
    }

    #nav-mobile {
        width: 30px;
        height: 20px;
        position: relative;
        margin-top: 15px;
        cursor: pointer;
        transition: all ease-in-out .3s;
        right: 15px;

        > span {
            height: 2px;
            border-radius: 3px;
            display: inline-block;
            background-color: $white;
            width: 100%;
            position: absolute;
            top: calc((30px - 4px) / 3);

            &:first-of-type {
                top: 0;
            }

            &:last-of-type {
                bottom: 0;
                top: auto;
            }
        }

        &.active {
            right: 210px;
        }
    }

    .fixed {
        #nav-mobile {
            zoom: .8;
            margin-top: 10px;
            right: 0;

            &.active {
                right: 245px;
            }
        }

        #nav-mobile-menu {
            li.label {
                line-height: 24px;
            }
        }
    }
}