.remodal {
    background-color: $white;
    box-shadow: 0 0 5px 0 rgba($secondary-color, .3);
    position: fixed;
    top: 100px;
    left: 50%;
    max-width: 70%;
    width: 800px;
    margin-left: -400px;

    @include breakpoint(medium down) {
        margin: 0 10%;
        width: 80%;
        top: 75px;
        left: 0;
    }

    .remodal-header {
        background-color: $primary-color;
        color: $white;
        position: relative;
        padding: 10px 0;
    }

    h1 {
        font-size: 2rem;
    }

    .remodal-close {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 1.5rem;
    }

    .remodal-content {
        padding: 20px 10px;
        text-align: left;
    }
}

.remodal-overlay {
    background-color: black;
    opacity: .3;
}