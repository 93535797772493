header#header {
    height: 50px;
    background-color: $secondary-color;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    transition: height ease-in-out .2s;
    z-index: 5;

    .logo {
        height: 45px;
        transition: height ease-in-out .2s;
    }

    &.fixed {
        height: 35px;

        .logo {
            height: 30px;
        }
    }
}